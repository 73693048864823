export default [
  [
    {
      name: "物业管理",
      child: [
        {
          name: "操作台",
          child: [
            {
              name: "物业收费",
              value: 79603,
            },
            {
              name: "意向金收费",
              value: 79604,
            },
            {
              name: "创建合同",
              value: 79605,
            },
            {
              name: "待处理合同数",
              value: 79606,
            },
            {
              name: "即将到期合同数",
              value: 79607,
            },
            {
              name: "投诉事件",
              value: 79608,
            },
            {
              name: "退款申请",
              value: 79609,
            },
            {
              name: "费用减免审批",
              value: 79610,
            },
            {
              name: "设置缴费审批",
              value: 79611,
            },
            {
              name: "优惠活动审批",
              value: 79612,
            },
            {
              name: "批量合同导入审批",
              value: 79613,
            },
            {
              name: "终止合同审批",
              value: 79614,
            },
          ],
          value: 11754,
        },
        {
          name: "物业概览",
          child: [],
          value: 45820,
        },
        {
          name: "商户管理",
          value: 4101,
          child: [
            {
              name: "商户列表",
              value: 41011,
              child: [
                {
                  name: "新增",
                  value: 410111,
                },
                {
                  name: "编辑",
                  value: 410112,
                },
                {
                  name: "删除",
                  value: 410113,
                },
                {
                  name: "详情",
                  value: 410114,
                },
              ],
            },
            {
              name: "临时商户列表",
              value: 41012,
              child: [
                {
                  name: "新增",
                  value: 410121,
                },
                {
                  name: "编辑",
                  value: 410122,
                },
                {
                  name: "删除",
                  value: 410123,
                },
                {
                  name: "详情",
                  value: 410124,
                },
                {
                  name: "租赁商铺",
                  value: 410125,
                },
              ],
            },
          ],
        },

        {
          name: "合同管理",
          child: [
            {
              name: "合同概览",
              child: [],
              value: 80035,
            },
            {
              name: "新增商户合同",
              child: [],
              value: 92169,
              // child:[{
              //     "name":"新增",
              //     "value":92170
              // }]
            },
            {
              name: "合同列表",
              child: [
                {
                  name: "导出",
                  child: [],
                  value: 95538,
                },
                {
                  name: "终止合同记录",
                  child: [],
                  value: 95539,
                },
                {
                  name: "增加合同",
                  child: [],
                  value: 24012,
                },
                {
                  name: "增加附属合同",
                  child: [],
                  value: 24013,
                },
                {
                  name: "编辑",
                  child: [],
                  value: 29389,
                },
                {
                  name: "删除",
                  child: [],
                  value: 26838,
                },
                {
                  name: "超级编辑",
                  child: [],
                  value: 29390,
                },
                {
                  name: "超级删除",
                  child: [],
                  value: 26891,
                },
                {
                  name: "撤回",
                  child: [],
                  value: 35005,
                },
                {
                  name: "第一步审批",
                  child: [],
                  value: 35006,
                },
                {
                  name: "第二步审批",
                  child: [],
                  value: 35007,
                },
                {
                  name: "第三步审批",
                  child: [],
                  value: 35008,
                },
                {
                  name: "第四步审批",
                  child: [],
                  value: 35013,
                },
                {
                  name: "第五步审批",
                  child: [],
                  value: 35014,
                },
                {
                  name: "第六步审批",
                  child: [],
                  value: 35015,
                },
                {
                  name: "第七步审批",
                  child: [],
                  value: 35016,
                },
                {
                  name: "第八步审批",
                  child: [],
                  value: 35017,
                },
                {
                  name: "第九步审批",
                  child: [],
                  value: 35018,
                },
                {
                  name: "第十步审批",
                  child: [],
                  value: 35019,
                },
                {
                  name: "第十一步审批",
                  child: [],
                  value: 35020,
                },
                {
                  name: "第十二步审批",
                  child: [],
                  value: 35021,
                },
                {
                  name: "审批",
                  child: [],
                  value: 35009,
                },
                {
                  name: "预终止",
                  child: [],
                  value: 35111,
                },
                {
                  name: "预终止审批第一步",
                  child: [],
                  value: 35010,
                },
                {
                  name: "预终止审批第二步",
                  child: [],
                  value: 35011,
                },
                {
                  name: "预终止审批第三步",
                  child: [],
                  value: 35012,
                },
                {
                  name: "详情",
                  child: [
                    {
                      name: "发送续签通知",
                      child: [],
                      value: 94663,
                    },
                    {
                      name: "续签合同",
                      child: [],
                      value: 91240,
                    },
                    {
                      name: "终止合同",
                      child: [],
                      value: 21508,
                    },
                    {
                      name: "查看合同文件",
                      child: [],
                      value: 61447,
                    },
                  ],
                  value: 34348,
                },
              ],
              value: 82936,
            },
            {
              name: "附属合同列表",
              value: 51000,
              child: [
                {
                  name: "导出",
                  value: 51001,
                },
                {
                  name: "详情",
                  value: 51002,
                },
                {
                  name: "编辑",
                  value: 51003,
                },
                {
                  name: "删除",
                  value: 51008,
                },
                {
                  name: "拒绝",
                  value: 51004,
                },
                {
                  name: "第一步审核",
                  value: 51005,
                },
                {
                  name: "第二步审核",
                  value: 51006,
                },
                {
                  name: "第三步审核",
                  value: 51007,
                },
              ],
            },
            {
              name: "批量合同导入",
              value: 82937,
              child: [
                {
                  name: "批量合同导入",
                  value: 82938,
                },
                {
                  name: "批量数据维护",
                  value: 82939,
                },
                {
                  name: "批量数据复核",
                  value: 82940,
                },
              ],
            },
            {
              name: "历史合同",
              child: [
                {
                  name: "导出",
                  child: [],
                  value: 16712,
                },
                {
                  name: "详情",
                  child: [],
                  value: 80576,
                },
              ],
              value: 81957,
            },
            {
              name: "合同设置",
              child: [
                {
                  name: "模板设置",
                  child: [
                    {
                      name: "新增合同模板",
                      child: [],
                      value: 6552,
                    },
                    {
                      name: "编辑合同",
                      child: [],
                      value: 73352,
                    },
                    {
                      name: "编辑编号规则",
                      child: [],
                      value: 98508,
                    },
                    {
                      name: "电子章上传",
                      child: [],
                      value: 95351,
                    },
                  ],
                  value: 24641,
                },
                {
                  name: "功能设置",
                  child: [
                    {
                      name: "线上合同消息设置",
                      child: [],
                      value: 33856,
                    },
                    {
                      name: "合同到期预警设置",
                      child: [],
                      value: 18174,
                    },
                    {
                      name: "合同到期消息通知设置",
                      child: [],
                      value: 10891,
                    },
                    {
                      name: "线下合同签订地址设置",
                      child: [],
                      value: 79212,
                    },
                  ],
                  value: 93443,
                },
              ],
              value: 68572,
            },
          ],
          value: 71477,
        },
        {
          name: "商铺管理",
          child: [
            {
              name: "商铺出租概览",
              child: [
                {
                  name: "导出",
                  child: [],
                  value: 35398,
                },
              ],
              value: 31652,
            },
            {
              name: "商铺列表",
              child: [
                {
                  name: "导出",
                  child: [],
                  value: 57610,
                },
                {
                  name: "历史出租信息",
                  child: [],
                  value: 40327,
                },
              ],
              value: 43269,
            },
            {
              name: "商铺类型",
              child: [
                {
                  name: "新增商铺类型",
                  child: [],
                  value: 16975,
                },
                {
                  name: "编辑",
                  child: [],
                  value: 95210,
                },
                {
                  name: "删除",
                  child: [],
                  value: 93723,
                },
              ],
              value: 69689,
            },
            {
              name: "商铺设置",
              child: [
                {
                  name: "新增商铺",
                  child: [],
                  value: 60008,
                },
                {
                  name: "批量新增商铺",
                  child: [],
                  value: 60009,
                },
                {
                  name: "批量商铺号导入",
                  child: [],
                  value: 60010,
                },
                {
                  name: "批量设置",
                  child: [],
                  value: 31143,
                },
                {
                  name: "导出",
                  child: [],
                  value: 87073,
                },
                {
                  name: "编辑",
                  child: [],
                  value: 79082,
                },
                {
                  name: "删除",
                  child: [],
                  value: 16301,
                },
              ],
              value: 13776,
            },
            {
              name: "楼栋管理",
              value: 69690,
              child: [
                {
                  name: "新增",
                  value: 69691,
                },
                {
                  name: "编辑",
                  value: 69692,
                },
                {
                  name: "删除",
                  value: 69693,
                },
              ],
            },
          ],
          value: 88428,
        },
        {
          name: "收费管理",
          child: [
            {
              name: "收费概览",
              child: [
                {
                  name: "收费情况表",
                  child: [
                    {
                      name: "导出",
                      child: [],
                      value: 39311,
                    },
                  ],
                  value: 51411,
                },
                {
                  name: "缴费户数统计表",
                  child: [
                    {
                      name: "导出",
                      child: [],
                      value: 16979,
                    },
                  ],
                  value: 44366,
                },
              ],
              value: 11531,
            },
            {
              name: "商户账单",
              child: [
                {
                  name: "基本账单",
                  child: [
                    {
                      name: "设置",
                      child: [],
                      value: 76048,
                    },
                    {
                      name: "导出",
                      child: [],
                      value: 77395,
                    },
                    {
                      name: "详情",
                      child: [
                        {
                          name: "导出",
                          child: [],
                          value: 34390,
                        },
                        {
                          name: "设置缴费记录",
                          child: [],
                          value: 34340,
                        },
                        {
                          name: "费用减免记录",
                          child: [],
                          value: 34341,
                        },
                        {
                          name: "退款",
                          child: [],
                          value: 75295,
                        },
                        {
                          name: "查看缴费凭证",
                          child: [],
                          value: 50953,
                        },
                        {
                          name: "设置缴费",
                          child: [],
                          value: 50954,
                        },
                        {
                          name: "费用减免",
                          child: [],
                          value: 50955,
                        },
                      ],
                      value: 77692,
                    },
                  ],
                  value: 44209,
                },
                {
                  name: "日常账单",
                  child: [
                    {
                      name: "批量商铺数据初始化",
                      child: [],
                      value: 37311,
                    },
                    {
                      name: "商户商铺初始化",
                      child: [],
                      value: 31323,
                    },
                    {
                      name: "手工抄表数据批量导入",
                      child: [],
                      value: 71854,
                    },
                    {
                      name: "导出",
                      child: [],
                      value: 54370,
                    },
                    {
                      name: "出账记录",
                      child: [
                        {
                          name: "导出",
                          child: [],
                          value: 60777,
                        },
                        {
                          name: "设置",
                          child: [],
                          value: 83230,
                        },
                        {
                          name: "历史确认信息",
                          child: [],
                          value: 11096,
                        },
                        {
                          name: "批量确认",
                          child: [],
                          value: 43836,
                        },
                        {
                          name: "确认出账",
                          child: [],
                          value: 55669,
                        },
                        {
                          name: "详细数据",
                          child: [],
                          value: 66142,
                        },
                        {
                          name: "全部出账",
                          child: [],
                          value: 32205,
                        },
                      ],
                      value: 84558,
                    },
                    {
                      name: "详情",
                      child: [
                        {
                          name: "缴费记录",
                          child: [],
                          value: 50651,
                        },
                        {
                          name: "设置缴费记录",
                          child: [],
                          value: 50652,
                        },
                        {
                          name: "导出",
                          child: [],
                          value: 12173,
                        },
                        {
                          name: "编辑",
                          child: [],
                          value: 80748,
                        },
                        {
                          name: "管理",
                          child: [],
                          value: 85201,
                        },
                        {
                          name: "历史账单",
                          child: [],
                          value: 99622,
                        },
                        {
                          name: "设置缴费",
                          child: [],
                          value: 99623,
                        },
                        {
                          name: "费用减免",
                          child: [],
                          value: 99624,
                        },
                      ],
                      value: 87177,
                    },
                  ],
                  value: 25853,
                },
              ],
              value: 93115,
            },
            {
              name: "转移记录",
              value: 11533,
            },
            {
              name: "收费记录",
              child: [
                {
                  name: "新建收费",
                  child: [],
                  value: 2761,
                },
                {
                  name: "详情",
                  child: [],
                  value: 86842,
                },
                {
                  name: "导出",
                  child: [],
                  value: 63656,
                },
                {
                  name: "作废",
                  child: [],
                  value: 63657
                },
                {
                  name: "开票",
                  child: [],
                  value: 63658
                },
                {
                  name: "查看",
                  child: [],
                  value: 63659
                },
              ],
              value: 51706,
            },
            {
              name: "意向金",
              child: [
                {
                  name: "新增意向金",
                  child: [],
                  value: 40343,
                },
                {
                  name: "导出",
                  child: [],
                  value: 1685,
                },
                {
                  name: "退款",
                  child: [],
                  value: 76626,
                },
                {
                  name: "作废",
                  child: [],
                  value: 16663,
                },
                {
                  name: "记录",
                  child: [],
                  value: 48499,
                },
                {
                  name: "缴费凭证",
                  child: [],
                  value: 69676,
                },
                {
                  name: "退款信息",
                  child: [],
                  value: 33391,
                },
              ],
              value: 16542,
            },
            {
              name: "收费设置",
              child: [
                {
                  name: "收费设置",
                  child: [
                    {
                      name: "基础费用消息通知设置",
                      child: [],
                      value: 76321,
                    },
                    {
                      name: "固定费用消息通知设置",
                      child: [],
                      value: 86003,
                    },
                    {
                      name: "基础费用减免设置",
                      child: [
                        {
                          name: "新增基础费用减免",
                          value: 86004,
                        },
                        {
                          name: "审批",
                          value: 86005,
                        },
                      ],
                      value: 55628,
                    },
                    {
                      name: "优惠活动设置",
                      child: [
                        {
                          name: "新增",
                          value: 55629,
                        },
                        {
                          name: "审批",
                          value: 55630,
                        },
                        {
                          name: "删除",
                          value: 55631,
                        },
                      ],
                      value: 4540,
                    },
                    {
                      name: "日常费用消息通知设置",
                      child: [],
                      value: 94054,
                    },
                    {
                      name: "现金收款抹零设置",
                      child: [],
                      value: 14766,
                    },
                    {
                      name: "商户能否修改缴费金额设置",
                      child: [],
                      value: 85096,
                    },
                  ],
                  value: 89587,
                },
                {
                  name: "日常收费设置",
                  child: [
                    {
                      name: "增加其它收费",
                      child: [],
                      value: 56306,
                    },
                    {
                      name: "删除",
                      child: [],
                      value: 95716,
                    },
                    {
                      name: "停用",
                      child: [],
                      value: 33438,
                    },
                    {
                      name: "设置",
                      child: [],
                      value: 92670,
                    },
                  ],
                  value: 22596,
                },
                {
                  name: "合同收费项设置",
                  child: [
                    {
                      name: "新增收费项",
                      child: [],
                      value: 77250,
                    },
                    {
                      name: "编辑",
                      child: [],
                      value: 29264,
                    },
                    {
                      name: "启用/停用",
                      child: [],
                      value: 55085,
                    },
                    {
                      name: "删除",
                      child: [],
                      value: 86673,
                    },
                  ],
                  value: 57594,
                },
                {
                  name: "收费项设置(附属)",
                  value: 16000,
                  child: [
                    {
                      name: "新增",
                      value: 16001,
                    },
                    {
                      name: "编辑",
                      value: 16002,
                    },
                    {
                      name: "启用",
                      value: 16003,
                    },
                    {
                      name: "停用",
                      value: 16005,
                    },
                    {
                      name: "删除",
                      value: 16004,
                    },
                  ],
                },
              ],
              value: 16155,
            },
            {
              name: "收费凭证",
              child: [
                {
                  name: "凭证编号编译规则设置",
                  child: [],
                  value: 59831,
                },
                {
                  name: "电子章上传",
                  child: [],
                  value: 2643,
                },
                {
                  name: "模板设置",
                  child: [],
                  value: 2644,
                },
              ],
              value: 34523,
            },
          ],
          value: 681,
        },
        {
          name: "投诉管理",
          child: [
            {
              name: "投诉概览",
              child: [],
              value: 44428,
            },
            {
              name: "详细列表",
              child: [
                {
                  name: "待处理",
                  child: [
                    {
                      name: "导出",
                      child: [],
                      value: 2947,
                    },
                    {
                      name: "详情",
                      child: [
                        {
                          name: "处理",
                          child: [],
                          value: 80233,
                        },
                      ],
                      value: 8187,
                    },
                  ],
                  value: 16371,
                },
                {
                  name: "已处理",
                  child: [
                    {
                      name: "导出",
                      child: [],
                      value: 1601,
                    },
                    {
                      name: "详情",
                      child: [],
                      value: 33417,
                    },
                  ],
                  value: 75312,
                },
              ],
              value: 81147,
            },
          ],
          value: 84719,
        },
        {
          name: "智能巡检",
          child: [
            {
              name: "巡检监管",
              value: 84720,
              child: [
                {
                  name: "异常问题查看",
                  value: 84721,
                },
              ],
            },
            {
              name: "巡检计划表",
              value: 84722,
              child: [
                {
                  name: "新增计划",
                  value: 847223,
                },
                {
                  name: "更新",
                  value: 847224,
                },
                {
                  name: "删除",
                  value: 847225,
                },
              ],
            },
            {
              name: "异常监管",
              value: 847226,
              child: [
                {
                  name: "异常问题设置",
                  value: 847227,
                },
                {
                  name: "导出",
                  value: 847228,
                },
                {
                  name: "详情",
                  value: 847229,
                },
              ],
            },
            {
              name: "巡检日志",
              value: 8472230,
              child: [
                {
                  name: "导出",
                  value: 8472231,
                },
                {
                  name: "异常问题查看",
                  value: 8472230,
                },
              ],
            },
            {
              name: "巡检设置",
              value: 847250,
              child: [
                {
                  name: "新增点位",
                  value: 847251,
                },
                {
                  name: "编辑",
                  value: 847253,
                },
                {
                  name: "导出",
                  value: 847254,
                },
              ],
            },
          ],
          value: 2423,
        },
        {
          name: "报修管理",
          value: 2424,
          child: [
            {
              name: "报修列表",
              value: 2425,
              child: [
                { name: "新增", value: 24251 },
                { name: "拒绝", value: 24252 },
                { name: "派单", value: 24253 },
                { name: "详情", value: 24254 },
                { name: "删除", value: 24255 },
              ],
            },
            {
              name: "维修记录",
              value: 2426,
              child: [],
            },
          ],
        },
        {
          name: "财务管理",
          child: [
            {
              name: "财务概览",
              child: [],
              value: 55053,
            },
            {
              name: "数据报表",
              child: [
                {
                  name: "导出",
                  value: 55054,
                },
              ],
              value: 55052,
            },
            {
              name: "财务报表",
              child: [
                {
                  name: "商户财务报表",
                  value: 55056,
                  child: [
                    {
                      name: "导出",
                      child: [],
                      value: 55057,
                    }
                  ]
                },
                {
                  name: "商铺财务报表",
                  value: 55058,
                  child: [
                    {
                      name: "导出",
                      child: [],
                      value: 55059,
                    }
                  ]
                },
              ],
              value: 55055,
            },
            {
              name: "对账管理",
              child: [
                {
                  name: "线上账单",
                  child: [
                    {
                      name: "确认核销",
                      child: [],
                      value: 7994,
                    },
                    {
                      name: "历史核销记录",
                      child: [
                        {
                          name: "取消核销",
                          child: [],
                          value: 85036,
                        },
                        {
                          name: "异常信息",
                          child: [],
                          value: 63003,
                        },
                        {
                          name: "详细数据",
                          child: [],
                          value: 61838,
                        },
                        {
                          name: "导出",
                          child: [],
                          value: 82862,
                        },
                      ],
                      value: 31221,
                    },
                    // {
                    //     "name": "汇总核销",
                    //     "child": [],
                    //     "value": 65982
                    // },
                    {
                      name: "导出",
                      child: [],
                      value: 11716,
                    },
                  ],
                  value: 52488,
                },
                {
                  name: "现金账单",
                  child: [
                    {
                      name: "确认核销",
                      child: [],
                      value: 64881,
                    },
                    {
                      name: "历史核销记录",
                      child: [
                        {
                          name: "取消核销",
                          child: [],
                          value: 14047,
                        },
                        {
                          name: "异常信息",
                          child: [],
                          value: 9379,
                        },
                        {
                          name: "详细数据",
                          child: [],
                          value: 74699,
                        },
                        {
                          name: "导出",
                          child: [],
                          value: 14276,
                        },
                      ],
                      value: 42972,
                    },
                    // {
                    //     "name": "汇总核销",
                    //     "child": [],
                    //     "value": 76525
                    // },
                    {
                      name: "导出",
                      child: [],
                      value: 68135,
                    },
                  ],
                  value: 53017,
                },
              ],
              value: 61182,
            },
            {
              name: "退款管理",
              child: [
                {
                  name: "设置",
                  child: [],
                  value: 69434,
                },
                {
                  name: "历史退款记录",
                  child: [
                    {
                      name: "导出",
                      child: [],
                      value: 35184,
                    },
                    {
                      name: "处理",
                      child: [],
                      value: 35185,
                    },
                    {
                      name: "查看凭证",
                      child: [],
                      value: 35186,
                    },
                  ],
                  value: 25826,
                },
                {
                  name: "处理",
                  child: [],
                  value: 86190,
                },
                {
                  name: "导出",
                  child: [],
                  value: 90529,
                },
              ],
              value: 85139,
            },
          ],
          value: 75788,
        },
        {
          name: "设备管理",
          value: 93800,
          child: [
            {
              name: "新增",
              value: 93801,
            },
            {
              name: "编辑",
              value: 93802,
            },
            {
              name: "删除",
              value: 93803,
            },
          ],
        },
        {
          name: "操作日志",
          child: [],
          value: 93664,
        },
        {
          name: "操作员设置",
          child: [
            {
              name: "操作员管理",
              child: [
                {
                  name: "新增",
                  child: [],
                  value: 21815,
                },
                // {
                //     "name": "联系人设置",
                //     "child": [],
                //     "value": 62442
                // },
                {
                  name: "编辑",
                  child: [],
                  value: 39295,
                },
                {
                  name: "重置密码",
                  child: [],
                  value: 90468,
                },
                {
                  name: "删除",
                  child: [],
                  value: 23025,
                },
                {
                  name: "导出",
                  child: [],
                  value: 49811,
                },
              ],
              value: 70394,
            },
            {
              name: "角色管理",
              child: [
                {
                  name: "新增",
                  child: [],
                  value: 44671,
                },
                {
                  name: "编辑",
                  child: [],
                  value: 38268,
                },
                {
                  name: "删除",
                  child: [],
                  value: 77225,
                },
                {
                  name: "导出",
                  child: [],
                  value: 15720,
                },
              ],
              value: 16024,
            },
          ],
          value: 73416,
        },
        {
          name: "系统设置",
          child: [
            {
              name: "收款账号设置",
              child: [],
              value: 13569,
            },
          ],
          value: 40437,
        },
      ],
      value: 60269,
    },
  ],
  [
    {
      name: "APP",
      child: [
        {
          name: "物业管理",
          child: [
            {
              name: "待处理合同",
              child: [],
              value: 67587,
            },
            {
              name: "日常费用出账确认",
              child: [],
              value: 40112,
            },
            {
              name: "商户信息",
              child: [],
              value: 42124,
            },
            {
              name: "费用监管",
              child: [],
              value: 61314,
            },
            {
              name: "市场数据",
              child: [],
              value: 79764,
            },
            {
              name: "巡检监控",
              child: [],
              value: 2553,
            },
            {
              name: "溯源信息",
              child: [],
              value: 80839,
            },
            {
              name: "日常巡检",
              child: [],
              value: 33975,
            },
            {
              name: "异常处理",
              child: [],
              value: 64397,
            },
            {
              name: "异常监管",
              child: [],
              value: 24874,
            },
            {
              name: "抄表统计",
              child: [],
              value: 73367,
            },
            {
              name: "投诉管理",
              child: [],
              value: 77858,
            },
            {
              name: "费用减免审批",
              child: [],
              value: 77859,
            },
            {
              name: "设置缴费审批",
              child: [],
              value: 77860,
            },
            {
              name: "优惠活动审批",
              child: [],
              value: 77861,
            },
            {
              name: "终止合同审批",
              child: [],
              value: 77863,
            },
            {
              name: "批量合同导入审批",
              child: [],
              value: 77864,
            },
            {
              name: "临时收费",
              child: [],
              value: 77865,
            },
            {
              name: "设备报修",
              child: [],
              value: 77866,
            },
            {
              name: "报修管理",
              child: [],
              value: 77867,
            },
            {
              name: "情况上报",
              child: [],
              value: 77868,
            },
          ],
          value: 82074,
        },
      ],
      value: 1160,
    },
  ],
];
