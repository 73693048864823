<template>
  <div class="contractlist">
    <div class="public-box">
      <el-form :inline="true">
        <el-form-item>
          <MyInput
            v-model="formInline.management_name"
            placeholder="输入角色名称"
          >
            <template slot="pre">角色名称:</template>
          </MyInput>
        </el-form-item>
        <el-form-item>
          <MyButton class="offset" left @click="search">
            搜索
            <template slot="preImage">
              <img src="../../unit/img/search.png" alt />
            </template>
          </MyButton>
        </el-form-item>
        <el-form-item>
          <MyButton class="offset" :accessId="44671" @click="addRole">
            新增
            <template slot="preImage">
              <img src="@/unit/img/zj.png" alt />
            </template>
          </MyButton>
        </el-form-item>
        <el-form-item>
          <MyButton
            :accessId="15720"
            class="offset"
            @click="
              $exported('/management/export', formInline, '角色列表.xlsx')
            "
          >
            <span>导出</span>
            <template slot="preImage">
              <img src="../../unit/img/down.png" alt />
            </template>
          </MyButton>
        </el-form-item>
      </el-form>
      <!-- table -->
      <div class="public-table">
        <el-table
          :data="tableList"
          :header-cell-style="{
            'text-align': 'center',
            background: 'rgb(245, 245, 245)',
          }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column
            label="角色名称"
            prop="management_name"
          ></el-table-column>
          <el-table-column label="创建时间" prop="create_at"></el-table-column>
          <el-table-column
            label="最近修改时间"
            prop="update_at"
          ></el-table-column>
          <el-table-column label="操作">
            <template #default="scope">
              <div class="slot-box">
                <pop
                  tips="编辑"
                  :accessId="38268"
                  @myclick="edit(scope.row)"
                  pop-right
                >
                  <img src="../../unit/img/edit.png" class="icon" alt />
                </pop>
                <pop tips="删除" :accessId="77225" @myclick="del(scope.row)">
                  <img src="@/assets/img/icon/deleted.png" class="icon" alt />
                </pop>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="public-page">
        <el-pagination
          :total="pageTotal"
          next-text="下一页"
          prev-text="上一页"
          :current-page="formInline.pageNum"
          :page-size="formInline.pageSize"
          layout="total,prev,pager,next"
        ></el-pagination>
      </div>
    </div>

    <el-dialog
      title="权限"
      :visible.sync="dialogVisible"
      top="2vh"
      width="50%"
      @close="roledialogclose"
      :close-on-click-modal="false"
    >
      <div style="display: flex; align-items: center; margin-bottom: 20px">
        <span style="min-width: 90px">角色名称：</span>
        <el-input
          style="width: 320px"
          placeholder="请输入角色名称"
          v-model.trim="roleName"
          clearable
        ></el-input>
      </div>
      <div class="tree">
        <el-tree
          v-if="dialogVisible"
          :data="treeData"
          show-checkbox
          default-expand-all
          node-key="value"
          ref="tree"
          :render-content="renderContent"
          :default-checked-keys="selectTree"
          highlight-current
          :props="defaultProps"
          v-loading="treeloading"
        ></el-tree>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="getCheckedKeys">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import justList from "./jurisdiction";
export default {
  data() {
    return {
      dialogVisible: false,
      dat: [],
      treeData: [],
      defaultProps: {
        children: "child",
        label: "name",
      },
      tableList: [],
      form: {
        merchantName: "",
      },
      selectTree: [],
      roleName: "",
      formInline: {
        pageNum: 1,
        pageSize: 10,
        management_name: "",
        role_worker_names: "",
      },
      pageTotal: 0,
      management_id: "",
      currRoot: [],
      roleWorkerNames: [],
      treeloading: false,
    };
  },
  created() {
    this.getList();
  },
  mounted() {},
  methods: {
    search() {
      this.formInline.pageNum = 1;
      this.getList();
    },
    getList() {
      this.$request
        .HttpPost("/management/list", {
          ...this.formInline,
        })
        .then((response) => {
          this.tableList = response.data.list;
          this.pageTotal = response.data.total;
        });
    },
    rowTree() {
      this.$nextTick(() => {
        let nodes = document.querySelectorAll(".treeRow");
        nodes.forEach((item) => {
          item.parentNode.style.cssFloat = "left";
          item.parentNode.style.styleFloat = "left";
          // item.parentNode.style.paddingLeft = '1px';
          // item.parentNode.parentNode.parentNode.firstChild.firstChild.style.paddingLeft = "54px"
        });
      });
    },
    setDatatree() {
      this.dat = justList;
      // for (let i = 0; i < this.dat.length; i++) {
      //   this.initData(this.dat[i], "", "T" + (i + 1));
      // }
      this.treeData = this.dat.reduce(function (a, b) {
        return a.concat(b);
      });
      this.setRow(this.treeData);
    },
    addRole() {
      this.dialogVisible = true;
      this.selectTree = [];
      this.treeloading = true;
      setTimeout(() => {
        this.setDatatree();
        this.rowTree();
        this.treeloading = false;
      }, 300);
    },
    getHalf(data, ids) {
      data.forEach((item) => {
        if (
          ids.includes(item.value) &&
          (!item.child || item.child.length == 0)
        ) {
          this.selectTree.push(item.value);
        } else if (item.child && item.child.length > 0) {
          this.getHalf(item.child, ids);
        }
      });
    },
    del(item) {
      this.$confirm("此操作将删除该职位，如该职位有人使用将无法删除", "提示", {
        type: "warning",
      }).then(() => {
        let url = "/management/delete";
        this.$request
          .HttpPost(url, {
            id: item.management_id,
          })
          .then(() => {
            this.$message({
              message: "成功",
              type: "success",
            });
            this.dialogVisible = false;
            this.formInline.pageNum = 1;
            this.getList();
          });
      });
    },
    edit(item) {
      this.treeloading = true;
      let ids = item.role_worker_id.split(",");
      let arr = [];
      ids.forEach((items) => {
        arr.push(items * 1);
      });
      console.log(arr);
      this.selectTree = [];
      this.dialogVisible = true;
      this.roleName = item.management_name;
      this.management_id = item.management_id;
      setTimeout(() => {
        this.setDatatree();
        this.getHalf(this.treeData, arr);
        this.rowTree();
        this.treeloading = false;
      }, 300);
    },
    getData(data) {
      data.forEach((item) => {
        if (
          this.currRoot.includes(item.value) ||
          this.currRoot.includes(item.value.toString())
        ) {
          this.roleWorkerNames.push(item.name);
          if (item.child && item.child.length > 0) {
            this.getData(item.child);
          }
        }
      });
    },
    positionSubmit() {
      this.roleWorkerNames = [];
      this.getData(this.treeData);
      let url = "/management/add";
      if (this.management_id) {
        url = "/management/edit";
      }
      // console.log(this.roleName, this.currRoot, this.roleWorkerNames)
      this.$request
        .HttpPost(url, {
          management_name: this.roleName,
          role_worker_id: this.currRoot.join(","),
          role_worker_names: this.roleWorkerNames.join(","),
          management_id: this.management_id,
        })
        .then(() => {
          this.$message({
            message: "成功",
            type: "success",
          });
          this.dialogVisible = false;
          this.formInline.pageNum = 1;
          this.getList();
        });
    },
    getCheckedKeys() {
      let arr1 = this.$refs.tree.getCheckedKeys();
      let arr2 = this.$refs.tree.getHalfCheckedKeys();
      let rootIDS = [].concat(arr1, arr2);
      this.currRoot = rootIDS;
      this.selectTree = arr1;
      // console.log(this.roleName, arr1)
      if (this.roleName == "") {
        this.$message.error("请输入角色名称");
        return false;
      }
      if (arr1.length == 0) {
        this.$message.error("请选择角色权限");
        return false;
      }
      this.positionSubmit();
    },
    setRow(data) {
      let bz = 0;
      data.forEach((item) => {
        if (!item.child || !item.child.length) {
          bz++;
        }
        if (item.child && item.child.length > 0) {
          this.setRow(item.child);
        }
      });
      if (bz == data.length) {
        data.forEach((item) => {
          item.display = "row";
        });
      }
    },
    renderContent(h, { node, data }) {
      let classname = "";
      if (data.display == "row") {
        classname = "treeRow";
      }
      return h("span", { class: classname }, node.label);
    },
    initData(data, bz, appNum) {
      let ind = bz ? 1 : 10;
      data.forEach((item) => {
        let strVal = ind.toString();
        if (ind.toString().length < 2 && bz) {
          strVal = `0${ind}`;
        }
        let val = (bz ? bz.toString() : "") + strVal;
        item.value = val;
        if (item.value.length < 19) {
          for (let i = item.value.length; i < 20; i++) {
            item.value += "0";
          }
        }
        item.value += appNum;
        ind = ind * 1 + 1;
        if (item.child && item.child.length > 0) {
          this.initData(item.child, val, appNum);
        }
      });
    },
    roledialogclose() {
      this.roleName = "";
      this.management_id = "";
      this.dat = [];
      this.treeData = [];
    },
  },
};
</script>
<style lang="scss" scoped>
.tree {
  height: 60vh;
  overflow: auto;
}
</style>
